import ReactLoading from "react-loading";
import MiovisionLogo from "../Chat/assets/miovision-logo.png";
import "./index.scss";

const LoadingPage = () => {
  return (
    <div className="loading-page">
      <div className="loading-content">
        <img src={MiovisionLogo} className="miovision-logo" />
        <h1 className="loading-text">Loading...</h1>
        <ReactLoading
          className="loading-animation"
          type="spin"
          color="#1A1A1A"
          height={75}
          width={75}
        />
      </div>
    </div>
  );
};

export default LoadingPage;
