import useOAuthService from "./services/OAuthService";
import React, { useState, useEffect } from "react";
import LoadingPage from "./views/LoadingPage";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ component }) => {
  const { verifyToken } = useOAuthService();
  const userToken = sessionStorage.getItem("userToken");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userToken) {
      (async () => {
        try {
          const result = await verifyToken(userToken);
          setIsAuthenticated(result);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setLoading(false);
    }
  }, [userToken, verifyToken]);

  if (loading) {
    return <LoadingPage />;
  }

  if (isAuthenticated) {
    return component;
  }

  return <Navigate to="/login" replace />;
};

export default PrivateRoute;
