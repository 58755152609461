import { useGoogleLogin } from "@react-oauth/google";
import { AppContext } from "../context/AppContext";
import { useCallback, useContext } from "react";
import { useState } from "react";
import axios from "axios";

const useOAuthService = () => {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const { setUser } = useContext(AppContext);

  const handleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      sessionStorage.setItem("userToken", codeResponse.access_token);
      getAuthorizedUser(codeResponse.access_token);
      if (await verifyToken(sessionStorage.getItem("userToken"))) {
        setShouldNavigate(true);
      }
    },
  });

  const handleLogout = useCallback(() => {
    // refresh page to clear state
    sessionStorage.removeItem("userToken");
    window.location.reload();
  }, []);

  const verifyToken = useCallback(async (token) => {
    let verified = false;
    try {
      await axios
        .get(process.env.REACT_APP_HTTP_API_ADDRESS + "/verify", {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            verified = true;
          }
        });
    } catch (error) {
      if (error.response && error.response.status === 401) {
        verified = false;
      } else {
        throw error;
      }
    }

    return verified;
  }, []);

  const getAuthorizedUser = useCallback(async (token) => {
    axios
      .get(
        "https://www.googleapis.com/oauth2/v3/userinfo?access_token=" + token
      )
      .then((response) => {
        setUser(response.data);
      });
  });

  return {
    handleLogin,
    handleLogout,
    verifyToken,
    getAuthorizedUser,
    shouldNavigate,
  };
};

export default useOAuthService;
