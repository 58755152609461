import "./index.scss";

const ResponseMessage = ({ response, setResponse }) => {
  return (
    <div className="api-response-message">
      <div className="api-response-message-code">
        <p>{response.status}</p>
      </div>
      <div className="api-response-message-text">
        <p>{response.data.message}</p>
      </div>
      <button
        className="api-response-message-button"
        onClick={() => {
          setResponse(null);
        }}
      >
        Close
      </button>
    </div>
  );
};

export default ResponseMessage;
