import { createContext } from "react";
import { useState } from "react";

export const AppContext = createContext("");

export const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState({
    user: null,
    picture: null,
  });
  const [theme, setTheme] = useState("light-mode");
  return (
    <AppContext.Provider value={{ user, setUser, theme, setTheme }}>
      {children}
    </AppContext.Provider>
  );
};
