import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactDOM from "react-dom/client";
import Chat from "./views/Chat";
import Login from "./views/Login";
import React from "react";
import "./index.css";
import PrivateRoute from "./PrivateRoute";
import { AppContextProvider } from "./context/AppContext";
import "./colors.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NoPage from "./views/NoPage";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AppContextProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <BrowserRouter>
          <Routes>
            <Route index element={<PrivateRoute component={<Chat />} />} />
            <Route
              path="/chat"
              element={<PrivateRoute component={<Chat />} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </AppContextProvider>
  </React.StrictMode>
);
