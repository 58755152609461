import axios from "axios";

// Pseudo-code for WhisperService.js
const WHISPER_ENDPOINT = "https://api.openai.com/v1/audio/transcriptions"; // Replace with actual Whisper endpoint
const OPENAI_API_KEY = process.env.REACT_APP_OPEN_AI_API_KEY; // Use your existing API key
const whisperTranscribe = async (voiceData, language) => {
  let data = new FormData();
  data.append("file", voiceData, "audio.wav");
  data.append("model", "whisper-1");
  if (language !== "all") {
    data.append("language", language);
  }
  console.log(voiceData);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: "https://api.openai.com/v1/audio/transcriptions",
    headers: {
      Authorization: `Bearer ${OPENAI_API_KEY}`,
      "Content-Type": "multipart/form-data",
    },
    data: data,
  };

  let returnedResponse = {};

  try {
    await axios.request(config).then((response) => {
      // console.log(response);
      returnedResponse = response;
    });
  } catch (error) {
    console.log(error);
  }

  return returnedResponse;
};
export { whisperTranscribe };
