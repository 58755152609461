import MiovisionLogo from "../Chat/assets/miovision-logo.png";
import "./index.scss";

const NoPage = () => {
  return (
    <div className="unfound-page">
      <div className="unfound-content">
        <img src={MiovisionLogo} className="miovision-logo" />
        <p className="unfound-text-error-message">Page Not Found</p>
      </div>
    </div>
  );
};

export default NoPage;
