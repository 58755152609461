import MiovisionLogo from "../Chat/assets/miovision-logo.png";
import useOAuthService from "../../services/OAuthService";
import { Navigate } from "react-router-dom";
import "./index.scss";

import React from "react";
import { FaGoogle } from "react-icons/fa";

const GoogleLoginButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="google-login-button">
      <FaGoogle className="google-icon" />
      Login with Google
    </button>
  );
};

const Login = () => {
  const { handleLogin, shouldNavigate } = useOAuthService();

  if (shouldNavigate) {
    return <Navigate to="/chat" />;
  }

  return (
    <div className="login-page">
      <div className="login-content">
        {/* <img className="miovision-logo" src={MiovisionLogo} /> */}
        <GoogleLoginButton onClick={handleLogin} />
      </div>
    </div>
  );
};

export default Login;
