import { useState } from "react";
import "./index.scss";
import Draggable from "react-draggable";

const languages = {
  en: "English",
  fr: "French",
  all: "All Languages",
};

const SettingsWindow = ({ settings, adjustSettings }) => {
  const closeWindow = () => {
    adjustSettings(settings.option, "selecting", false);
  };

  const handleSelectChange = (event) => {
    adjustSettings(settings.option, "value", event.target.value);
  };

  return (
    <>
      {settings.selecting && (
        <Draggable bounds={{ bottom: "100px" }}>
          <div className="settings-window">
            <h1>{settings.option.toUpperCase()}</h1>
            <div className="settings-window-content">
              {settings.type == "range" && (
                <input
                  value={settings.value}
                  type="range"
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={(e) =>
                    adjustSettings(settings.option, "value", e.target.value)
                  }
                  className="settings-window-input-range"
                />
              )}
              {settings.type == "options" && (
                <select
                  name="language"
                  id="select"
                  className="settings-window-input-options"
                  onChange={(e) =>
                    adjustSettings(settings.option, "value", e.target.value)
                  }
                >
                  <option value={settings.value}>
                    {languages[settings.value]}
                  </option>
                  {Object.keys(languages).map((key) => {
                    if (key != settings.value) {
                      return <option value={key}>{languages[key]}</option>;
                    }
                  })}
                </select>
              )}
            </div>
            <div
              className="settings-window-confirm"
              onClick={() => {
                closeWindow();
              }}
            >
              Confirm
            </div>
          </div>
        </Draggable>
      )}
    </>
  );
};

export default SettingsWindow;
