import React, { useState, useEffect } from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { VscFoldUp, VscFoldDown } from "react-icons/vsc";
import { IoSettings } from "react-icons/io5";
import "./index.scss";
import SettingsWindow from "../SettingsWindow";

const ArticlesSideBar = ({
  documents,
  authentication,
  dataSource,
  settings,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [userMenu, setUserMenu] = useState(false);

  const adjustSettings = (option, settingsKey, settingsValue) => {
    settings.set(
      settings.value.map((setting) => {
        if (setting.option === option) {
          return { ...setting, [settingsKey]: settingsValue };
        }
        return setting;
      })
    );
  };

  const handleResize = () => {
    if (window.innerWidth <= 800) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      setSidebarExpanded(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isLoaded) {
    }
    setIsLoaded(true);
  }, [isLoaded]);

  return (
    <>
      {settings.value.map((setting, key) => {
        console.log("LOADING SETTING HERE", setting);
        return (
          <SettingsWindow
            settings={setting}
            adjustSettings={adjustSettings}
            key={key}
          />
        );
      })}
      {isMobile && (
        <div className="side-bar-top">
          <AiOutlineMenuUnfold
            className="side-bar-expand"
            onClick={() => {
              setSidebarExpanded((previous) => !previous);
            }}
          />
          <h1 className="side-bar-title-mobile" style={{ textAlign: "left" }}>
            Suggested Articles
          </h1>
        </div>
      )}
      <div
        className={
          sidebarExpanded && isMobile ? "side-bar-expanded" : "side-bar"
        }
      >
        <div className="side-bar-content">
          <>
            {isMobile ? null : (
              <div className="page-listing-title">
                <h1 className="page-listing-title-text">Suggested Articles</h1>
              </div>
            )}
            <div className="page-listings">
              {documents.all &&
                documents.all.map((document, key) => {
                  return (
                    <div className="page-listing" key={"page" + key}>
                      <a href={document.url} target="_blank" rel="noreferrer">
                        <span>{document.title}</span>
                        <p>Updated Date: {document.date}</p>
                      </a>
                    </div>
                  );
                })}
            </div>
            <div className="data-source-selection-title">
              <h1 className="data-source-selection-title-text">Data Sources</h1>
            </div>
            <div className="page-listings" style={{ height: "50vh" }}>
              {["All sources", "Confluence", "Drive", "Jira", "Slack"].map(
                (source, key) => {
                  return (
                    <div
                      className={
                        dataSource.selected === source
                          ? "page-listing-selected"
                          : "page-listing"
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      key={"source" + key}
                      onClick={() => {
                        dataSource.setSelected(source);
                      }}
                    >
                      <span>{source}</span>
                    </div>
                  );
                }
              )}
            </div>
          </>
        </div>
        <div
          className="user-bar"
          onClick={() => {
            setUserMenu(!userMenu);
          }}
        >
          <IoSettings className="user-bar-options" />
          <p className="user-full-name">{authentication.user.name}</p>
          <img src={""} className="user-bar-icon" alt="" />
          <div
            className="user-menu"
            style={{ display: userMenu ? "block" : "none" }}
          >
            <ul>
              <li
                onClick={() => {
                  adjustSettings("language", "selecting", true);
                }}
              >
                {" "}
                Language Settings
              </li>
              <li
                onClick={() => {
                  adjustSettings("temperature", "selecting", true);
                }}
              >
                {" "}
                Adjust Temperature
              </li>
              <li> Change Prompt</li>
              <li
                onClick={() => {
                  authentication.handleLogout();
                }}
              >
                {" "}
                Sign Out
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticlesSideBar;
