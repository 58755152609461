import React, { useState, useContext, useEffect, useRef } from "react";
import { whisperTranscribe } from "../../utils/WhisperApiCall";
import ResponseMessage from "../../components/ResponseMessage";
import useOAuthService from "../../services/OAuthService";
import MiovisionLogo from "./assets/miovision-logo.png";
import MiovisionNode from "./assets/miovision-node.png";
import useMediaRecorder from "@wmik/use-media-recorder";
import SideBar from "../../components/ArticlesSideBar";
import useWebSocket from "react-use-websocket";
import ReactLoading from "react-loading";
import AWS from "aws-sdk";

import { FaTrafficLight, FaFilePdf } from "react-icons/fa";
import { IoRemoveCircleSharp } from "react-icons/io5";
import { AiFillFileAdd } from "react-icons/ai";
import { MdLightMode } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { AppContext } from "../../context/AppContext";
import { FaMicrophone } from "react-icons/fa";

import "./index.scss";

const Chat = () => {
  const [theme, setTheme] = useState("light-mode");
  const [recommendedDocuments, setRecommendedDocuments] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { handleLogout, getAuthorizedUser } = useOAuthService();
  const [response, setResponse] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isResponding, setIsResponding] = useState(false);
  const [audioRecordingComplete, setAudioRecordingComplete] = useState(false);
  const { user } = useContext(AppContext);
  const [file, setFile] = useState(null);
  const { sendMessage, lastMessage } = useWebSocket(
    process.env.REACT_APP_SOCKET_API_ADDRESS
  );
  const [dataSource, setDataSource] = useState("All sources");
  const messageBoxRef = useRef(null);
  const fileInputRef = useRef(null);
  const [userSettings, setUserSettings] = useState([
    {
      option: "language",
      selecting: false,
      value: "all",
      type: "options",
    },
    {
      option: "temperature",
      selecting: false,
      value: 0,
      type: "range",
    },
  ]);

  let { mediaBlob, stopRecording, startRecording } = useMediaRecorder({
    recordScreen: false,
    blobOptions: { type: "audio/wav" },
    mediaStreamConstraints: { audio: true, video: false },
  });

  const changeTheme = () => {
    setTheme(theme === "dark-mode" ? "light-mode" : "dark-mode");
  };

  const addAnswer = (answer) => {
    setAnswers([...answers, answer]);
  };

  const addQuestion = (question) => {
    setQuestions([...questions, question]);
  };

  const handleQuestion = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      askGPT(currentQuestion);
    }
  };

  const scrollDown = () => {
    const element = document.getElementsByClassName(
      "conversation-container"
    )[0];
    element.scrollTop = element.scrollHeight;
  };

  const chatHistory = (questions, answers) => {
    const contextMessages = [];
    for (let i = 0; i < Math.min(questions.length, answers.length); i++) {
      contextMessages.push({
        question: questions[i],
        answer: answers[i],
      });
    }
    return contextMessages;
  };

  const reloadPage = () => {
    console.log("Reloading...");
    window.location.reload();
  };

  const handleFileChange = (e) => {
    console.log(e.target.files);
    const fileSubmission = e.target.files[0];

    if (fileSubmission.size > 10000000) {
      alert("File should be smaller than 1MB");
      return;
    }

    setFile(fileSubmission);
  };

  const uploadFile = async (fileSubmission) => {
    try {
      const fileSubmissionUrl = `uploads/${Date.now()}_${fileSubmission.name}`;
      console.log(fileSubmissionUrl);
      const S3_BUCKET = process.env.REACT_APP_AWS_S3_BUCKET;
      const REGION = process.env.REACT_APP_AWS_REGION;

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      });

      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: fileSubmissionUrl,
        Body: fileSubmission,
      };

      var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          console.log(
            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          );
        })
        .promise();

      let fileURL = await upload.then((err, data) => {
        console.log(err);
        return fileSubmissionUrl;
      });

      return fileURL;
    } catch (e) {
      console.log(e);
    }
  };

  const askGPT = async (question) => {
    let questionToAsk = currentQuestion || question;
    if (isResponding || questionToAsk === "") {
      return;
    }
    setCurrentAnswer("");
    setIsLoading(true);
    setIsResponding(true);
    setRecommendedDocuments([]);
    setCurrentQuestion("");
    addQuestion(questionToAsk);
    setFile(null);
    fileInputRef.current.value = null;
    scrollDown();

    let fileURL = null;
    if (file !== null) {
      console.log("File is not null");
      fileURL = await uploadFile(file);
    } else {
      console.log("File is null");
    }

    try {
      const message = JSON.stringify({
        question: questionToAsk,
        authorization: sessionStorage.getItem("userToken"),
        action: "chat",
        messages: chatHistory(questions, answers),
        file: fileURL,
        dataSource: dataSource === "All sources" ? null : dataSource,
      });

      console.log(message);

      sendMessage(message);
    } catch (e) {
      console.log(e);
    }
  };

  const handleMediaBlob = async (blob) => {
    let response = await whisperTranscribe(blob, userSettings[0].value);
    setCurrentQuestion(response.data.text);
    setAudioRecordingComplete(true);
  };

  useEffect(() => {
    messageBoxRef.current.style.height = "auto";
    console.log(messageBoxRef.current);
    let height = 10;
    if (file) {
      console.log("File added");
      messageBoxRef.current.style.paddingTop = "65px";
      if (messageBoxRef.current.scrollHeight < 65) {
        height += 65;
      }
    } else {
      messageBoxRef.current.style.paddingTop = "5px";
    }

    height += messageBoxRef.current.scrollHeight;

    if (height > 200) {
      height = 200;
    }
    messageBoxRef.current.style.height = `${height}px`;
    setCurrentQuestion(messageBoxRef.current.value);
  }, [file, currentQuestion]);

  useEffect(() => {
    if (lastMessage !== null) {
      let data = JSON.parse(lastMessage.data);
      // data.answer && console.log(data.answer.split("\n"));
      // data.error && reloadPage();
      data.recommendedDocuments &&
        setRecommendedDocuments((previous) => {
          return [...previous, ...data.recommendedDocuments];
        });
      data.answer && setCurrentAnswer(data.answer);
      data.answer && setIsLoading(false);
      if (data.responseComplete === true) {
        addAnswer(currentAnswer);
        setIsResponding(false);
      }
      scrollDown();
    }
  }, [lastMessage, setAnswers, currentAnswer]);

  useEffect(() => {
    if (user === null || user.picture === null) {
      getAuthorizedUser(sessionStorage.getItem("userToken"));
    }
  }, [getAuthorizedUser, user]);

  useEffect(() => {
    console.log(mediaBlob);
    if (isRecording) {
      console.log("Recording...");
      startRecording();
    } else {
      console.log("Stopped Recording");
      stopRecording();
    }
  }, [isRecording]);

  useEffect(() => {
    console.log(`mediaBlob: ${mediaBlob}`);
    if (mediaBlob) {
      handleMediaBlob(mediaBlob);
    }
  }, [mediaBlob]);

  useEffect(() => {
    if (audioRecordingComplete) {
      askGPT();
      setAudioRecordingComplete(false);
    }
  }, [audioRecordingComplete]);

  return (
    <div className={theme + " app"}>
      <SideBar
        documents={{ all: recommendedDocuments }}
        authentication={{
          user: user,
          handleLogout: handleLogout,
        }}
        dataSource={{
          selected: dataSource,
          setSelected: setDataSource,
        }}
        settings={{
          value: userSettings,
          set: setUserSettings,
        }}
      />
      <main>
        {isLoading && (
          <div className="loading-container">
            <ReactLoading
              type="spokes"
              color="#0065ED"
              height={100}
              width={50}
            />
          </div>
        )}
        <div className="conversation-header">
          {theme === "dark-mode" ? (
            <MdDarkMode className="color-theme" onClick={() => changeTheme()} />
          ) : (
            <MdLightMode
              className="color-theme"
              onClick={() => changeTheme()}
              color="white"
            />
          )}
          <img
            src={MiovisionLogo}
            className="miovision-logo-container"
            alt="Global Traffic Technologies"
          />
          {response && (
            <ResponseMessage response={response} setResponse={setResponse} />
          )}
        </div>
        <div className="conversation-container">
          {questions.map((question, key) => (
            <div key={"conversation-response" + key}>
              <div className="question">
                <div className="avatar">
                  <img src={user.picture} />
                </div>
                <p>{question}</p>
              </div>
              <div className="answer">
                <div className="avatar">
                  <img src={MiovisionNode} />
                </div>
                <p>
                  {key === questions.length - 1 ? currentAnswer : answers[key]}
                </p>
              </div>
            </div>
          ))}
          {questions.length < 1 ? (
            <div className="suggested-questions">
              {[
                "What is Mio One?",
                "What is Surtrac?",
                "What is Digital Intersection?",
                "What is Opticom?",
              ].map((question, key) => {
                return (
                  <div
                    className="suggested-question"
                    key={"suggested-question" + key}
                    onClick={() => {
                      askGPT(question);
                    }}
                  >
                    <p>{question}</p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div className="user-input-container">
          <div className="insert-file-button">
            <label
              className="insert-file-button-label"
              htmlFor="insert-file-button-input"
            >
              <AiFillFileAdd
                className="insert-file-button-icon"
                color="white"
              />
            </label>
            <input
              id="insert-file-button-input"
              type="file"
              accept="application/pdf, image/*"
              ref={fileInputRef}
              onChange={async (e) => {
                handleFileChange(e);
              }}
            />
          </div>
          <div class="ask-gpt-input">
            {file && (
              <div className="ask-gpt-file-input">
                <div className="ask-fpt-file-input-icon">
                  <FaFilePdf />
                </div>
                <div className="ask-gpt-file-input-text">
                  <h4>{file.name}</h4>
                  <p>{String(file.type).split("/")[1]}</p>
                </div>
                <IoRemoveCircleSharp
                  className="ask-gpt-file-input-remove"
                  enableBackground={true}
                  color="black"
                  fill="red"
                  size={"100%"}
                  onClick={() => {
                    setFile(null);
                    fileInputRef.current.value = null;
                  }}
                />
              </div>
            )}
            <textarea
              ref={messageBoxRef}
              rows={1}
              className="ask-gpt-input-textarea"
              placeholder="Ask a Question"
              value={currentQuestion}
              onChange={(event) => {
                setCurrentQuestion(event.target.value);
              }}
              onKeyDown={(event) => handleQuestion(event)}
            ></textarea>
          </div>
          {/* <FaTrafficLight
            onClick={() => askGPT(currentQuestion)}
            className="ask-gpt-button"
            color="white"
          /> */}
          <FaMicrophone
            color={isRecording ? "#0065ed" : "white"}
            className="record-input-button"
            onClick={() => {
              setIsRecording(!isRecording);
            }}
          />
          {/* {isLoading && (
            <div
              className="stop-gpt-button"
              onClick={() => {
                console.log("Stopping...");
                console.log(chatHistory(questions, answers));
              }}
            >
              Stop Generating
            </div>
          )} */}
        </div>
      </main>
    </div>
  );
};

export default Chat;
